<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">Add Battery Type</h1>
          <v-form ref="investmentForm">
            <v-label>Battery Type</v-label>
            <v-text-field
              v-model="batteryType"
              outlined
              placeholder="Battery Type"
              class="mt-1"
            ></v-text-field>

            <v-btn @click="validate" v-if="!loading" large>Add Battery Type</v-btn>
            <v-btn  v-if="loading" large loading></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "AddBrand",
  data: () => ({
    batteryType: undefined,
    loading:false
  }),
  methods: {
    ...mapActions(['postBatteryType','getSingleBatteryType']),
    async validate() {
      this.loading = true;
     let data = {};
     if(this.batteryType != undefined){
       data.batteryType = this.batteryType;
     }
      if (this.$route.params.id != undefined) {
        data._id =  this.$route.params.id;
      }
      await this.postBatteryType(data);
      if(this.allBatteryType.status == 200 || this.allBatteryType.status == 201){
        this.$router.push("/business/batteryTypes");
      }
      this.loading = false;
    },
   
  },
  computed:{
    ...mapGetters(['allBatteryType'])
  },
  async created() {
    if (this.$route.params.id) {
      await this.getSingleBatteryType(this.$route.params.id);
      this.batteryType = this.allBatteryType.results.batteryType;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.imageBox {
  img {
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>